<template>
  <el-form
      class="lb-m-0 lb_top w-100"
      label-position="top"
      label-width="140px"
  >
    <div class="row">
      <div class="col-md-6">
        <el-form-item
            :label="$t('time_entry.spent_on')"
            class="mt-0"
            required
            v-permission="['pm-set-logged-date']"
        >
          <el-date-picker
              :class="checkinputRequired('spent_on') ? 'validate-error' : ''"
              :pickerOptions="{ firstDayOfWeek: 1 }"
              format="yyyy-MM-dd"
              name="spent_on"
              placeholder=""
              style="width: 100%;"
              type="date"
              v-model="form.spent_on"
          >
          </el-date-picker>
          <small
              class="help-block"
              v-if="backend_errors.spent_on && !checkinputRequired('spent_on')"
          >
            <span v-html="validationTranslate(backend_errors.spent_on[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item
            :label="$t('time_entry.hours')"
            required
        >
          <el-input
              :class="checkinputRequired('hours') ? 'validate-error' : ''"
              name="hours"
              placeholder="000:00"
              type="text"
              v-mask="'###:##'"
              v-model="form.hours"
          />
          <small
              class="help-block"
              v-if="backend_errors.hours && !checkinputRequired('hours')"
          >
            <span v-html="validationTranslate(backend_errors.hours[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
            :label="$t('time_entry.activity')"
            required
            v-module="['activity']"
        >
          <el-select
              :class="checkinputRequired('activity_id') ? 'validate-error' : ''"
              :placeholder="$t('time_entry.activity')"
              filterable
              name="activity_id"
              style="width: 100%;"
              v-model="form.activity_id"
          >
            <el-option
                :key="value.id"
                :label="value.name"
                :value="value.id"
                v-for="value in activities"
            >
            </el-option>
          </el-select>
          <small
              class="help-block"
              v-if="backend_errors.activity_id && !checkinputRequired('activity_id')"
          >
            <span v-html="validationTranslate(backend_errors.activity_id[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
            :label="$t('time_entry.comments')"
            required
        >
          <el-input
              :class="checkinputRequired('comments') ? 'validate-error' : ''"
              @keyup.ctrl.enter.native="save"
              name="comments"
              resize="none"
              rows="5"
              type="textarea"
              v-model="form.comments"
          >
          </el-input>
          <small
              class="help-block"
              v-if="backend_errors.comments && !checkinputRequired('comments')"
          >
            <span v-html="validationTranslate(backend_errors.comments[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item class="m-0 ptx-20 d-flex justify-content-end">
          <el-button
              :loading="loading.save"
              @click.prevent.stop="save"
              type="primary"
          >
            {{ $t('system.save') }}
          </el-button>
          <el-button @click="$emit('cancel')">
            {{ $t('system.cancel') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'timeLogForm',
  props: [
    'activities',
    'saveURL',
    'id',
  ],
  data() {
    return {
      form: {
        spent_on: new Date(),
      },
      loading: {
        save: false,
      },
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  methods: {
    save() {
      this.loading.save = true;
      const requiredInputs = [];
      if (this.form.hours === '000:00') {
        requiredInputs.push('hours');
      }
      this.checkValidation(requiredInputs).then(() => {
        this.form.spent_on = moment(this.form.spent_on).format('YYYY-MM-DD');
        // TODO przerobić na Backu "issue_id" -> "id"
        this.form.issue_id = this.id;
        axios.post(this.saveURL, this.form)
            .then((response) => {
              this.loading.save = false;
              this.$emit('saved', response);
            })
            .catch((error) => {
              this.$emit('error', error);
              this.loading.save = false;
              this.backend_errors = error.response.data.errors;
            });
      }).catch(() => {
        this.loading.save = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
